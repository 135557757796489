import React from 'react'
import PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Trans } from 'gatsby-plugin-react-i18next'
import { Section } from '../Hero/styles'
import { PressContainer, LogoContainer, Row, Header, Center } from './styles'
import { IS_FOREIGN_COUNTRY } from '../../utils/constants'

const defaultLanguage = process.env.GATSBY_I18N_DEFAULT_LANGUAGE

const LogoLink = ({ href, image, alt, id }) => (
  <LogoContainer id={id}>
    <a href={href} target="_blank" rel="noopener noreferrer">
      <GatsbyImage image={image} alt={alt} />
    </a>
  </LogoContainer>
)

const FramePress = ({ page }) => {
  const {
    buzzfeedLogo,
    wallStreet,
    todayShowLogo,
    wired,
    tomsGuide,
    skyNews,
    newsAu,
    torontoSun,
    financialPost,
    desired,
    okMagazine,
  } = useStaticQuery(graphql`
    query FramePressQuery {
      wallStreet: file(relativePath: { eq: "press/wsj.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      todayShowLogo: file(relativePath: { eq: "press/today-show-logo.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      buzzfeedLogo: file(relativePath: { eq: "press/buzzfeed-stacked-logo.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      wired: file(relativePath: { eq: "redesign/wired.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      tomsGuide: file(relativePath: { eq: "press/tomsGuide.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      skyNews: file(relativePath: { eq: "press/sky.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      newsAu: file(relativePath: { eq: "press/news.au.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      torontoSun: file(relativePath: { eq: "press/toronto-sun.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      financialPost: file(relativePath: { eq: "press/financial-post.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      desired: file(relativePath: { eq: "press/desired.webp" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      okMagazine: file(relativePath: { eq: "press/ok-magazine.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  const logoData = {
    todayShow: {
      href: 'https://www.today.com/video/tree-dazzler-illuminated-sheers-twig-lights-and-other-holiday-tech-834920003980?v=b',
      image: todayShowLogo.childImageSharp.gatsbyImageData,
      alt: 'Today Show Logo',
    },
    wallStreet: {
      href: 'https://www.wsj.com/articles/5-reasons-digital-picture-frames-are-finally-worth-buying-11602782129',
      image: wallStreet.childImageSharp.gatsbyImageData,
      alt: 'Wall Street Journal Logo',
    },
    wired: {
      href: 'https://www.wired.com/gallery/best-digital-picture-frames/',
      image: wired.childImageSharp.gatsbyImageData,
      alt: 'Wired Logo',
      id: 'large',
    },
    tomsGuide: {
      href: 'https://www.tomsguide.com/best-picks/best-digital-photo-frames/',
      image: tomsGuide.childImageSharp.gatsbyImageData,
      alt: 'Toms Guide Logo',
      id: 'xl',
    },
    skyNews: {
      href: 'https://www.imagematrix.tech/first-look-skylight-15-inch-digital-photo-frame-launches-in-australia/',
      image: skyNews.childImageSharp.gatsbyImageData,
      alt: 'Sky News Logo',
      id: 'large',
    },
    buzzfeed: {
      href: 'https://www.buzzfeed.com/yiyang/splurge-worthy-mothers-day-gifts-shell-absolutely-adore',
      image: buzzfeedLogo.childImageSharp.gatsbyImageData,
      alt: 'Buzzfeed Logo',
    },
    newsAu: {
      href: 'https://www.news.com.au/checkout/life/leisure/what-the-checkout-team-is-loving-this-week/news-story/8b3bb275fbf96d4a9f6456829a8a3da3',
      image: newsAu.childImageSharp.gatsbyImageData,
      alt: 'News.com.au Logo',
      id: 'large',
    },
    torontoSun: {
      href: 'https://torontosun.com/pmn/business-wire-news-releases-pmn/skylight-launches-skylight-calendar-in-canada-just-in-time-for-the-busy-holiday-season',
      image: torontoSun.childImageSharp.gatsbyImageData,
      alt: 'Toronto Sun Logo',
      id: 'large',
    },
    financialPost: {
      href: 'https://financialpost.com/pmn/business-wire-news-releases-pmn/skylight-launches-skylight-calendar-in-canada-just-in-time-for-the-busy-holiday-season',
      image: financialPost.childImageSharp.gatsbyImageData,
      alt: 'Financial Post Logo',
      id: 'large',
    },
    desired: {
      href: 'https://www.desired.de/weihnachten/weihnachtsgeschenk-fuer-mama/',
      image: desired.childImageSharp.gatsbyImageData,
      alt: 'Desired Logo',
      id: 'large',
    },
    okMagazine: {
      href: 'https://www.ok-magazin.de/lifestyle/weihnachten-2023-die-schoensten-x-mas-geschenke-fuer-deinen-boyfriend-62646.html#digitaler-bilderrahmen',
      image: okMagazine.childImageSharp.gatsbyImageData,
      alt: 'Ok Magazine Logo',
    },
  }

  const logosUSA = [logoData.todayShow, logoData.wallStreet, logoData.wired, logoData.tomsGuide]

  const defaultLogos = [logoData.wired, logoData.buzzfeed, logoData.wallStreet, logoData.todayShow]

  const internationalLogosAU = [
    logoData.skyNews,
    logoData.wired,
    logoData.wallStreet,
    logoData.newsAu,
    logoData.buzzfeed,
  ]

  const internationalLogosCA = [
    logoData.torontoSun,
    logoData.wired,
    logoData.financialPost,
    logoData.wallStreet,
  ]

  const internationalLogosDE = [
    logoData.wired,
    logoData.desired,
    logoData.okMagazine,
    logoData.wallStreet,
    logoData.buzzfeed,
  ]
  let PRESS_ICONS

  if (IS_FOREIGN_COUNTRY) {
    switch (defaultLanguage) {
      case 'en-au':
        PRESS_ICONS = internationalLogosAU
        break
      case 'en-ca':
        PRESS_ICONS = internationalLogosCA
        break
      case 'de':
        PRESS_ICONS = internationalLogosDE
        break
      default:
        PRESS_ICONS = defaultLogos
    }
  } else {
    PRESS_ICONS = logosUSA
  }
  return (
    <Section>
      <PressContainer>
        <Center>
          <Header>
            <Trans>Featured In</Trans>
          </Header>
        </Center>
        <Row>
          {PRESS_ICONS.map((icon, index) => (
            <LogoLink key={index} href={icon.href} image={icon.image} alt={icon.alt} id={icon.id} />
          ))}
        </Row>
      </PressContainer>
    </Section>
  )
}

LogoLink.propTypes = {
  href: PropTypes.string,
  image: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  id: PropTypes.string,
}

FramePress.propTypes = {
  page: PropTypes.string,
}

export default FramePress
